// paragraph-video.js
import React from "react";
import { graphql } from "gatsby";
import Embed from 'react-tiny-oembed'

export const ParagraphVideo = ({ node }) => {

    const url = node.relationships.field_video.field_media_oembed_video

    console.log(url)

    return (
        <figure>
            <Embed
            url={url}
            options={{ theme: 'dark' }}
        />
            <figcaption dangerouslySetInnerHTML={{ __html: node.field_caption }} />
        </figure>
    )
};

export const fragment = graphql`
    fragment ParagraphVideo on paragraph__video {
        id
        field_caption
            relationships {
                field_video {
                    field_media_oembed_video
                }
            }
    }
`;