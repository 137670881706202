// paragraph-content-basic.js
import React from "react";
import { graphql } from "gatsby";

export const ParagraphContentBasic = ({ node }) => (
    <div dangerouslySetInnerHTML={{ __html: node.text?.value }} />
);

export const fragment = graphql`
    fragment ParagraphContentBasic on paragraph__content_basic {
        id
        text: field_text {
            format
            processed
            value
        }
    }
`;