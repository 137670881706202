// paragraph-image.js
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const ParagraphImage = ({ node }) => (
    <div className="paragraph">
            {node.relationships.field_image && <GatsbyImage image={getImage(node.relationships.field_image.relationships.field_media_image_1.localFile)} alt='' className="mb-2" />}
            <div className="text-xl font-light italic mb-12" dangerouslySetInnerHTML={{ __html: node.field_caption }} />
    </div>
);

export const fragment = graphql`
    fragment ParagraphImage on paragraph__content_image {
        id
        field_caption
        relationships {
            field_image {
                relationships {
                    field_media_image_1 {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                quality: 90, 
                                width: 1200,
                                aspectRatio: 1.6
                            )
                        }
                    }
                    }
                }
            }
        }
    }
`;