// paragraph-content-quote.js
import React from "react";
import { graphql } from "gatsby";

export const ParagraphContentQuote = ({ node }) => (
    <div>
    <div dangerouslySetInnerHTML={{ __html: node.field_text.processed }} />
    <blockquote>{node.field_pull_quote}</blockquote>
    </div>
);

export const fragment = graphql`
    fragment ParagraphContentQuote on paragraph__content_text_quote {
        id
        field_text {
            format
            processed
            value
        }
        field_pull_quote
    }
`;