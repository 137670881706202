// paragraphHelpers.js
import React from "react";

import { ParagraphContentBasic } from "./paragraph-basic-content";
import { ParagraphImage } from "./paragraph-image";
import { ParagraphContentQuote } from "./paragraph-content-quote";
import { ParagraphContentImage } from "./paragraph-content-image";
import { ParagraphParallax } from "./paragraph-parallax";
import { ParagraphVideo } from "./paragraph-video";

const components = {
    paragraph__content_basic: ParagraphContentBasic,
    paragraph__content_image: ParagraphImage,
    paragraph__content_text_quote: ParagraphContentQuote,
    paragraph__content_basic_image_text: ParagraphContentImage,
    paragraph__parallax_image: ParagraphParallax,
    paragraph__video: ParagraphVideo
};

export const getParagraph = node => {
    if (components.hasOwnProperty(node.type)) {
        const ParagraphComponent = components[node.type];
        return <ParagraphComponent key={node.id} node={node} />;
    }
    return <p className="font-red-800 font-extrabold" key={node.id}>Unknown type {node.__typename}</p>;
};