// paragraph-content-image.js
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const ParagraphContentImage = ({ node }) => (
    <div className="flex">
        <div dangerouslySetInnerHTML={{ __html: node.field_text?.processed }} />
        {node.relationships.field_image && <GatsbyImage image={getImage(node.relationships.field_image.relationships.field_media_image_1.localFile)} alt='' className="mb-1" />}
    </div>
);

export const fragment = graphql`
    fragment ParagraphContentImage on paragraph__content_basic_image_text {
        id
        field_text {
            format
            processed
            value
        }
        relationships {
            field_image {
                relationships {
                    field_media_image_1 {
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    }
                }
            }
        }
    }
`;