import React from "react"
import { graphql, Link } from "gatsby"
import { getSrc, GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout/layout"
import { getParagraph } from "../components/paragraphs/paragraphHelpers";
import Seo from "../components/layout/seo"
import ContactCTABlock from "../components/blocks/ContactCTA"

import Share from "../components/blocks/Share";2


const ArticlePost = ({ data }) => {

  const paragraphs = data.page.relationships.paragraphs.map(getParagraph)
  const title = data.page.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const slug = data.page.path.alias
  const twitterHandle = data.site.siteMetadata.twitterHandle

  let thumbnail = ''
  let teaser = ""

  const findFirstContent = paragraphs.find(o => o.type.name == "ParagraphContentBasic");

  if (findFirstContent) {
    teaser = findFirstContent.props.node.text.value.replace("</p>", "</p> - ").replace(/(<([^>]+)>)/gi, "").replace("&nbsp;", " ").substring(0, 275) + "..."
  }



  {data.page.relationships?.field_image?.relationships?.field_media_image_1 && (
    thumbnail = siteUrl+getSrc(data.page.relationships.field_image.relationships.field_media_image_1.localFile.childImageSharp?.gatsbyImageData)
  )}

  return (
    <Layout>
      <Seo 
        title={data.page.title}
        meta={[
          {
            name: `og:image:secure_url`,
            content: thumbnail,
          },
          {
            name: 'description',
            content: teaser,
          },
          {
            name: 'og:description',
            content: teaser,
          }
        ]}
      />
      <hr />

      <div className="lg:flex lg:space-x-32 bg-yellow container mx-auto py-16">


        <main className="prose prose-sm sm:prose lg:prose-lg xl:prose-2xl">
          <h1 className="text-2xl font-bold">{data.page.title}</h1>
          <div className="text-sm mb-4">{data.page.created}</div>
          <div className="paragraphs">
            {paragraphs}
          </div>
          <footer>
            <Share
              socialConfig={{
                twitterHandle,
                config: {
                  url: `${siteUrl}${slug}`,
                  title,
                },
              }}
            />
          </footer>
        </main>

        <div className="lg:w-1/5 prose lg:border-l lg:pl-16">
          <div className="text-3xl mb-8 border-b-4 border-primary pb-4 font-semibold">Other reads</div>
          <div className="items">
            {data.other.edges.map((item, key) => {
              return (
                <Link to={item.node.path.alias} className="text-sm no-underline mb-6 block" activeStyle={{ color: "#ccc" }} key={key}>{item.node.title}</Link>
              )
            })}
          </div>
        </div>

      </div>

      <section className="py-12 bg-primary-50">
        <div className="container">
          <ContactCTABlock />
        </div>
      </section>

    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    page: nodeArticle(
      id: { eq: $id }
      ) 
    {
      title
      path {
              alias
            }
      created(formatString: "DD MMMM, YYYY")
      relationships {
        paragraphs: field_content_builder {
          type: __typename
          ...ParagraphContentBasic
          ...ParagraphImage
          ...ParagraphContentQuote
          ...ParagraphContentImage
          ...ParagraphParallax
          ...ParagraphVideo
        }
        field_image {
          relationships {
            field_media_image_1 {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 90, width: 1200)
                }
              }
            }
          }
        }
      }
    },
    other: allNodeArticle(
      filter: {relationships: {field_article_destination: {elemMatch: {name: {eq: "Marine"}}}}}
      sort: { fields: [created], order: DESC}
      limit: 5
    ){
    edges {
      node {
        id
        drupal_internal__nid
        title
        created(formatString: "DD MMMM, YYYY")
        status
        path {
          alias
        }
        
      }
    }
  }
  site {
		siteMetadata {
			siteUrl
			twitterHandle
		}
	}
  }
`

export default ArticlePost