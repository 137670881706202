import React from 'react';
import PropTypes from 'prop-types';
import {
    FaFacebookF,
    FaLinkedinIn,
    FaTwitter
} from 'react-icons/fa';
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
} from 'react-share';


const Share = ({ socialConfig, tags }) => (
	<div className="flex items-center space-x-4 my-12 text-base font-bold">
        <LinkedinShareButton url={socialConfig.config.url} className="flex space-x-2 items-center p-3 py-2 rounded text-white bg-linkedin" title={socialConfig.config.title} resetButtonStyle={false} >
			<span className="icon">
				<FaLinkedinIn />
			</span>
			<span className="text">LinkedIn</span>
		</LinkedinShareButton>
		<TwitterShareButton url={socialConfig.config.url} className="flex space-x-2 items-center p-3 py-2 rounded text-white bg-twitter" title={socialConfig.config.title} resetButtonStyle={false} via={socialConfig.twitterHandle.split('@').join('')} hashtags={tags} >
			<span className="icon">
				<FaTwitter />
			</span>
			<span className="text">Twitter</span>
		</TwitterShareButton>
		<FacebookShareButton url={socialConfig.config.url} className="flex space-x-2 items-center p-3 py-2 rounded text-white bg-facebook" resetButtonStyle={false} >
			<span className="icon">
				<FaFacebookF />
			</span>
			<span className="text">Facebook</span>
		</FacebookShareButton>
	</div>
);

Share.propTypes = {
	socialConfig: PropTypes.shape({
		twitterHandle: PropTypes.string.isRequired,
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
	tags: [],
};

export default Share;